import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
//import { HTMLContent } from '../components/Content'
import MarkdownContent from '../components/MarkdownContent'

export const FaqPageTemplate = ({ title, questions }) => {
  return (
    <section className="section section--gradient" style={{ paddingTop: 0 }}>
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                {title}
              </h2>
              <div className="content">
                <div className="summary">
                  <ul>
                    {questions.map((question, idx) => (
                      <li key={idx}>
                        <a href={`#question-${idx + 1}`}>{question.text}</a>
                      </li>
                    ))}
                  </ul>
                </div>
                <ul style={{ listStyleType: 'none', marginLeft: 0, marginTop: '2rem' }}>
                  {questions.map((question, idx) => (
                    <li key={idx} id={`question-${idx + 1}`} style={{ marginBottom: '2rem' }}>
                      <h3>{question.text}</h3>
                      <MarkdownContent content={question.answer} />
                      {/* <HTMLContent content={question.answer} /> */}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

FaqPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    answer: PropTypes.string
  }))
}

FaqPageTemplate.defaultProps = {
  questions: []
}

const FaqPage = ({ data }) => {
  const { markdownRemark: pageContent } = data

  return (
    <Layout>
      <FaqPageTemplate
        title={pageContent.frontmatter.title}
        questions={pageContent.frontmatter.questions}
      />
    </Layout>
  )
}

FaqPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default FaqPage

export const faqPageQuery = graphql`
  query FaqPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title,
        questions {
          text,
          answer
        }
      }
    }
  }
`
